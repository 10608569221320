import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdgroupCheckGuardGuard } from './shared/common/adgroup-check-guard.guard';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    //canActivate: [AdgroupCheckGuardGuard]

  },
  // {
  //   path: 'covid-lite-home',
  //   loadChildren: () => import('./covid-lite-home/covid-lite-home.module').then(m => m.CovidLiteHomeModule)
  // },
  {
    path: 'facility-admin',
    loadChildren: () => import('./facility-admin/facility-admin.module').then(m => m.FacilityAdminModule)
  },
  {
    path: 'facility-home',
    loadChildren: () => import('./facility-profile/facility-profile.module').then(m => m.FacilityProfileModule)
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent
  },
  {
    path: 'outreach-user',
    loadChildren: () => import('./outreach-user/outreach-user.module').then(m => m.OutreachUserModule)
  },
  {
    path: 'r3ap',
    loadChildren: () => import('./r3ap-home/r3ap-home.module').then(m => m.R3apHomeModule)
  },
  {
    path: 'icf',
    loadChildren: () => import('./icf-facility/icf-facility.module').then(m => m.IcfFacilityModule)
  },
  {
    path: 'dodd-admin',
    loadChildren: () => import('./dodd-admin/dodd-admin.module').then(m => m.DoddAdminModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
