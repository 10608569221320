import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule, HeaderComponent, FooterComponent, SectionComponent, ConsumerinfoComponent } from './shared';
import { MaterialModule } from './material.module';
import { LoginModule } from './login/login.module';
//import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
//import { FacilityProfileComponent } from './facility-profile/facility-profile.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SectionComponent,
    ConsumerinfoComponent,
    AccessDeniedComponent//,
    //FacilityProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialModule,
    LoginModule,
    //MDBBootstrapModule.forRoot()
  ],
  
  providers: [ ],
  bootstrap: [AppComponent],
  entryComponents:[]
})
export class AppModule { }
