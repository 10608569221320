<div class="container-fluid  bg-white border-bottom box-shadow mb-3" style="box-shadow: 0 0.25rem 0.75rem #002244 !important ">

  <div style="display:flex; justify-content:space-between">

    <h3 class="my-auto" style="margin-left:2rem"><a class="navbar-brand" href="#"><img src="assets/images/ODA_Logo_340x88_Transparent.png" /></a></h3>
    
    <h4 style="align-self: center; color: #002244 !important " class="title">COVID-19 Care Center</h4>

  <div *ngIf="contextFacility" style="margin-top: 10px; margin-right: 5px">
    <div style="    font-weight: bold;font-size: 15px;color: #670808;">{{ contextFacility?.name }} - {{ contextFacility?.stateId}} </div>
    <div style=" font-size: 13px;color:gray; " >
      <div >{{ contextFacility?.address1 }} </div>
      <div >{{ contextFacility?.city }}, {{ contextFacility?.state }} {{ contextFacility?.zip }}</div>
    </div>
  </div>

  <div *ngIf="contextPharmacy">
    <div style="    font-weight: bold;font-size: 15px;color: #670808;">{{ contextPharmacy?.name     }} </div>
    <div style=" font-size: 13px;color:gray; ">
      <div>{{ contextPharmacy?.address }} </div>
      <div>{{ contextPharmacy?.city }}, {{ contextPharmacy?.state }} {{ contextPharmacy?.zip }}</div>
    </div>
  </div>


  </div>
</div>
