<div class="custom-dialog-container  message">
  
  <h2 mat-dialog-title [ngStyle]="{'text-align':data.alignTitle} ">
    <mat-icon *ngIf="data.sucess" style="color:#39813f;margin-right: 5px;">check_circle</mat-icon>
    <mat-icon *ngIf="data.warn" style="color: #ed6102; margin-right: 5px;">warning</mat-icon>
    <mat-icon *ngIf="data.error" style="color: #ff0000; margin-right: 5px;">error</mat-icon>

    <span *ngIf="data.title" class="title">{{data.title}}</span>
  </h2>

  <mat-dialog-content class="mat-typography">

    <div *ngIf="data.message"
         [innerHTML]="data.message | safeHtml"
         [ngStyle]="{'text-align':data.alignMessage }"
         ></div>
    <br />
    <br />
  </mat-dialog-content>

  <mat-dialog-actions>
    <div *ngIf="data.confirm" style="width:100%; text-align:center; ">

      <button mat-raised-button (click)="dialogRef.close('yes')" color="primary">
        <mat-icon>check</mat-icon> Yes
      </button>
      <button mat-raised-button (click)="dialogRef.close('no')" color="warn">
        <mat-icon>close</mat-icon> No
      </button>

    </div>
    <div *ngIf="!data.confirm" style="width:100%; text-align:center; ">
      <button mat-raised-button (click)="dialogRef.close('ok')" color="primary">Ok</button>
    </div>
  </mat-dialog-actions>


</div>
