import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private loaderService: LoaderService) { }

  /*Removed the text message after check Icon in all sections*/
  success(message) {
    /*
    Swal.fire({
      title: "<span style='color:white'></span>",
      background: 'MediumSeaGreen',
      position: 'center-end',
      //type: 'success',      
      showConfirmButton: false,
      toast: true,
      timer: 3500
    });
    */
    this.loaderService.hideLoader();
  }

  error(message) {
    /*
    Swal.fire({
      //  background: 'IndianRed',
      position: 'center',
      //type: 'error',
      title: 'Error Occured',
      text: message,
      allowOutsideClick: false
    });
    */
    this.loaderService.hideLoader();
  }

  showErrorMessage(message: string, title?: string): any {
    const swalWithMaterialButtons = Swal.mixin({
      customClass: {
        confirmButton: 'mat-focus-indicator mat-raised-button mat-button-base mat-primary',
        title: 'text-danger'
      },
      buttonsStyling: false
    });
    if (!title) {
      title = "Error";
    }
    let optns: SweetAlertOptions = {
      title: title,
      html: message,
      icon: 'error',
      showConfirmButton: true
    }
    return swalWithMaterialButtons.fire(optns);
  }

  showSucessMessage(message: string, title?: string): any {
    const swalWithMaterialButtons = Swal.mixin({
      customClass: {
        confirmButton: 'mat-focus-indicator mat-raised-button mat-button-base mat-primary',
        title: 'text-success'
      },
      buttonsStyling: false
    });
    if (!title) {
      title = "Success";
    }
    let optns: SweetAlertOptions = {
      title: title,
      html: message,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#700017'
    }
    return swalWithMaterialButtons.fire(optns);
  }

  showConfirm(message: string, title: string): Promise<SweetAlertResult> {

    const swalWithMaterialButtons = Swal.mixin({
      customClass: {  
        confirmButton: 'mat-focus-indicator mat-raised-button mat-button-base  mat-accent m-3',
        cancelButton  : 'mat-focus-indicator mat-raised-button mat-button-base  mat-warn m-3',
        title: 'text-danger'
      },
      buttonsStyling: false
    });

    let optns: SweetAlertOptions = {
      title: title,
      html: message,
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#ed6102',
      cancelButtonColor: '#700017',
    }
    return swalWithMaterialButtons.fire(optns);
  }

  clearLoader() {
    this.loaderService.hideLoader();
  }

  showLoader() {
    this.loaderService.showLoader();
  }
}
